import React, { useEffect, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import {
  Col,
  Container,
  Image,
  Row,
  Spinner,
  Button,
  Breadcrumb,
  Stack,
  Badge,
  Table,
} from "react-bootstrap";
import { Icon } from "@iconify/react";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import axiosClient from "../../axiosClient";
import ProductCard from "../ProductCard/ProductCard";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import * as appConstants from "../../constants";
import axios from "axios";
import MakeOffer from "../MakeOffer/MakeOffer";
import { handleAddCart, getTotals } from "../Slices/CartSlice";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify/dist/react-toastify";
import { setCategory, setIndustry, setType } from "../Slices/FilterSlice";
import useDocumentTitle from "../../DocumentTitle";
import "./InventoryDetail.css";
import SeoHelmet from "../Seo/SeoHelmet";
import { Helmet } from "react-helmet";
import LoginModal from "../LoginPage/LoginModal";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { da } from "date-fns/locale";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

// import GetShippingCost from "./GetShippingCost/GetShippingCost";

function InventoryDetail() {
  // useDocumentTitle("PC | Inv. Detail");
  const { id } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [data, setData] = useState({});
  const [gallery, setGallery] = useState({});
  const [relatedItems, setRelatedItems] = useState({});
  const [lowestPrice, setLowestPrice] = useState(null);

  const userData = JSON.parse(localStorage.getItem("login"));
  const [loading, setLoading] = useState(false);
  const [show, setshow] = useState(null);
  const [showLater, setshowLater] = useState(false);
  const [showLogin, setShowLogin] = useState(null);
  const [isBuyNow, setIsBuyNow] = useState(false);
  const [stock, setStock] = useState(null);
  const [comfortDropPrice, setComfortDropPrice] = useState(null);
  const [comfortPrice, setComfortPrice] = useState(null);
  const [delRioDropPrice, setDelRioDropPrice] = useState(null);
  const [delRioPrice, setDelRioPrice] = useState(null);
  const [houstonDropPrice, setHoustonDropPrice] = useState(null);
  const [houstonPrice, setHoustonPrice] = useState(null);

  const handleshow = () => {
    if (show == null) {
      setshow(true);
    } else {
      setshow(!show);
    }
  };

  const parser = new DOMParser();
  let imageUrl = null;

  // Check if data.item and data.item.coverImageURL are defined
  if (data.item && data.item.coverImageURL) {
    const htmlDocument = parser.parseFromString(
      data.item.coverImageURL,
      "text/html"
    );

    // Get the image element
    const imgElement = htmlDocument.querySelector("img");

    // Extract the image URL
    imageUrl = imgElement ? imgElement.src : null;
  }

  console.log("imageUrl", imageUrl);

  const tooltip = (
    <Tooltip id="tooltip">
      At Pro Containers, we provide tailored options to suit your shipping
      container needs. For the most cost-effective solution, choose our drop
      shipping service, which offers reduced pricing when purchasing either two
      20-foot containers or a single 40-foot container. If your order doesn't
      meet these criteria, standard pricing will apply. For those preferring to
      select their container in person, we can transport it to our facility;
      however, this option incurs an additional transportation fee, and the
      container will be priced higher to cover these extra costs. Select the
      option that aligns with your requirements and budget.
    </Tooltip>
  );

  const tooltip2 = (
    <Tooltip id="tooltip">
      This pricing is available if you purchase a quantity of (2) 20'
      containers, or (1) 40' container
    </Tooltip>
  );
  const handleShowLogin = () => {
    if (showLogin == null) {
      if (showLogin == null) {
        setShowLogin(true);
      }
    } else {
      setShowLogin(!showLogin);
    }
  };

  useEffect(() => {
    const getItemById = async () => {
      try {
        const res = await axiosClient.get("GetItemById/" + Number(id));

        if (res.qbRecordId) {
          const imgList = await axiosClient.get(
            "GetItemImageGallery/" + Number(res.qbRecordId)
          );
          const sortedImgData = imgList.sort(compare);
          setGallery({ data: sortedImgData });
        }
        if (res.relatedCategoryId) {
          const relatedData = await axiosClient.get(
            "GetRandomItemByCategory/" + Number(res.relatedCategoryId)
          );
          setRelatedItems({ data: relatedData });
        }
        setData({ item: res });
        setComfortDropPrice(res.comfortDropPrice);
        setComfortPrice(res.comfortPrice);
        setDelRioDropPrice(res.delRioDropPrice);
        setDelRioPrice(res.delRioPrice);
        setHoustonDropPrice(res.houstonDropPrice);
        setHoustonPrice(res.houstonPrice);
      } catch (error) {
        console.log(error);
      }
    };
    getItemById();
  }, []);

  const totalStock =
    data && data.item
      ? (parseInt(data.item.comfortStock, 10) || 0) +
        (parseInt(data.item.delRioStock, 10) || 0) +
        (parseInt(data.item.houstonStock, 10) || 0) +
        (parseInt(data.item.dropStockMaster, 10) || 0)
      : 0;

  const calculateLowestPrice = () => {
    const prices = [
      comfortDropPrice,
      comfortPrice,
      delRioDropPrice,
      delRioPrice,
      houstonDropPrice,
      houstonPrice,
    ].filter((price) => !isNaN(price) && price !== 0);

    if (prices.length > 0) {
      const minPrice = Math.min(...prices);

      setLowestPrice(minPrice.toLocaleString("en-US"));
    } else {
      setLowestPrice(null);
    }
  };

  // const locations = data.item.

  useEffect(() => {
    calculateLowestPrice();
  }, [data.item]);

  function compare(a, b) {
    if (a.sortOrder < b.sortOrder) {
      return -1;
    } else if (a.sortOrder > b.sortOrder) {
      return 1;
    } else {
      return 0;
    }
  }
  function addCart(selectedItem) {
    var loginData = JSON.parse(localStorage.getItem("login"));
    if (loginData == null) {
      setIsBuyNow(false);
      handleShowLogin();
    } else {
      dispatch(handleAddCart(selectedItem));
      dispatch(getTotals());
      toast.success("Container Added to Cart");
    }
  }
  function buyNow(selectedItem) {
    var loginData = JSON.parse(localStorage.getItem("login"));
    if (loginData == null) {
      setIsBuyNow(true);
      handleShowLogin();
    } else {
      dispatch(handleAddCart(selectedItem));
      dispatch(getTotals());
      navigate("/ShoppingCart");
    }
  }

  // console.log("TotalStock", stock);
  async function saveLaterItem(selectedItem) {
    setLoading(true);
    var loginData = JSON.parse(localStorage.getItem("login"));
    if (loginData == null) {
      navigate("/login");
    } else {
      try {
        var body = {
          id: 0,
          userId: userData.user.id,
          ItemId: Number(selectedItem.id),
        };
        await axiosClient.post("saveSaveLaterInfo", body).then((res) => {
          setLoading(false);
          setshowLater(true);
          toast.success("Successfully saved for later");
        });
      } catch (error) {
        setLoading(false);
      }
    }
  }

  const params = {
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
  };

  const gotToInventoryByIndustry = (item) => {
    var selectItem = {
      id: item.relatedIndustrId,
      name: item.industryName,
    };
    dispatch(setIndustry(selectItem));
    navigate("/Containers", { state: { industryId: item.id } });
  };

  const gotToInventoryByCategory = (item) => {
    var selectItem = {
      id: item.relatedCategoryId,
      name: item.categoryName,
    };
    dispatch(setCategory(selectItem));
    navigate("/Containers", { state: { categoryId: item.relatedCategoryId } });
  };

  const gotToInventoryByType = (item) => {
    var selectItem = {
      id: item.relatedTypeId,
      name: item.typeName,
    };
    dispatch(setType(selectItem));
    navigate("/Containers", { state: { typeId: item.relatedTypeId } });
  };

  // console.log(data.item);

  function appendparaToUrl(name, value) {
    const searchParams = new URLSearchParams(location.search);
    const paramName = name;
    const paramValue = value;
    if (!searchParams.has(paramName)) {
      searchParams.append(paramName, paramValue);
    } else {
      const existingParamValue = searchParams.get(paramName);
      if (existingParamValue !== paramValue) {
        searchParams.delete(paramName);
        searchParams.append(paramName, paramValue);
      }
    }

    return searchParams.toString();

    // const searchParams = new URLSearchParams(location.search);
    // const paramName = name;
    // const paramValue = value;

    // if (!searchParams.has(paramName)) {
    //   searchParams.append(paramName, paramValue);
    //   navigate({ search: `?${searchParams.toString()}` });
    // }
  }

  function formattedValue(myValue) {
    if (myValue !== null && myValue !== undefined) {
      let v = Number(myValue).toFixed(2);
      if (v > 0) {
        return v;
      } else {
        return "0.00";
      }
    } else {
      return "0.00";
    }
  }

  const locations = data.item
    ? data.item.location.split(",").map((location) => location.trim())
    : [];

  console.log(locations);

  return (
    <>
      <Header />

      {data && data.item && (
        <Helmet>
          <title>{data.item ? `${data.item.title}` : "Loading"}</title>
          <meta name="manufacturer" content={data.item.menufacturer} />
          <meta name="model" content={data.item.model} />
          <meta name="asset-number" content={data.item.assetNumber} />
          <meta name="hp-amp-kw-kva" content={data.item.hP_AMP_KW_KVA} />
          <meta name="voltage" content={data.item.voltage} />
          <meta name="length-inches" content={data.item.lengthInches} />
          <meta
            name="packaging-weight"
            content={data.item.estiamtedPackaginWeight}
          />
          <meta name="year" content={data.item.year} />
          <meta name="amps" content={data.item.amps} />
          <meta name="phase" content={data.item.phase} />
          <meta name="width-inches" content={data.item.widthInches} />
          <meta name="height-inches" content={data.item.heightInches} />
          <meta name="weight-lbs" content={data.item.weightLBS} />
          <link
            rel="canonical"
            href={`https://kingsurplus.com/InventoryDetail/${id}`}
          />
          <script type="application/ld+json">{`
          {
            "@context": "http://schema.org",
            "@type": "Product",
            "name": "${
              data.item.title
                ? data.item.title.toString().replace(/"/g, " ")
                : ""
            }",
            "description": "${
              data.item.title
                ? data.item.description.toString().replace(/"/g, " ")
                : ""
            }",
            "brand": {
              "@type": "Brand",
              "name": "${data.item.menufacturer}"
            },
            "image": "${imageUrl}",
            "additionalProperty": [
              {
                "@type": "PropertyValue",
                "name": "Inspections",
                "value": "${data.item.guaranteeOrAsis && data.item.condition}"
              },
              {
                "@type": "PropertyValue",
                "name": "Offer",
                "value": "${formattedValue(data.item.salePrice)}" 
              },
              {
                "@type": "PropertyValue",
                "name": "Appraised Value",
                "value": "${formattedValue(data.item.salePrice)}"
              },
              {
                "@type": "PropertyValue",
                "name": "Manufacturer",
                "value": "${data.item.menufacturer}"
              },
              {
                "@type": "PropertyValue",
                "name": "Model",
                "value": "${
                  data.item.model
                    ? data.item.model.toString().replace(/"/g, " ")
                    : ""
                }"
              },
              {
                "@type": "PropertyValue",
                "name": "Amps",
                "value": "${data.item.amps}"
              },
              {
                "@type": "PropertyValue",
                "name": "Voltage",
                "value": ${data.item.voltage ? `${data.item.voltage}` : '"N/A"'}
              },
              {
                "@type": "PropertyValue",
                "name": "Phase",
                "value": "${data.item.phase}"
              },
              {
                "@type": "PropertyValue",
                "name": "HP / AMP / KW / KVA",
                "value": "${data.item.hP_AMP_KW_KVA}"
              },
              {
                "@type": "PropertyValue",
                "name": "Image",
                "value": "${imageUrl}"
              }
              ]
            ]
          }
        `}</script>
        </Helmet>
      )}

      {data && data.item && (
        <div className="d-margin">
          <SeoHelmet
            title={data.item.title}
            description={data.item.description}
            name="Pro Container"
            type="website"
            // canonical={data.item.title}
            url={window.location.href}
          />

          <Container>
            <Breadcrumb className="pt-4 ">
              <Breadcrumb.Item>
                <Link className="backBtn" to="/Containers">
                  <Icon icon="material-symbols:arrow-back-ios-new-rounded" />{" "}
                  Back
                </Link>
              </Breadcrumb.Item>
              {data.item.industryName && (
                <Breadcrumb.Item
                  onClick={() => {
                    gotToInventoryByIndustry(data.item);
                  }}
                >
                  {data.item.industryName}
                </Breadcrumb.Item>
              )}

              {data.item.categoryName && (
                <Breadcrumb.Item
                  onClick={() => {
                    gotToInventoryByCategory(data.item);
                  }}
                >
                  {data.item.categoryName}
                </Breadcrumb.Item>
              )}
              {data.item.typeName && (
                <Breadcrumb.Item
                  onClick={() => {
                    gotToInventoryByType(data.item);
                  }}
                >
                  {data.item.typeName}
                </Breadcrumb.Item>
              )}
            </Breadcrumb>
            <Row>
              <Col lg={6}>
                <div className="SliderImage">{setSlider()}</div>
              </Col>
              {itemDescription()}
            </Row>
            {inspectionReport()}
            <div className="similar-product">
              {relatedItems &&
                relatedItems.data &&
                relatedItems.data.length > 0 && (
                  <>
                    <h4>Similar Items Nearby</h4>
                    <Row>
                      {relatedItems.data.map((product) => (
                        <Col key={product.id} xl={3} lg={4} md={6}>
                          <ProductCard RecentProductList={product} />
                        </Col>
                      ))}
                    </Row>
                  </>
                )}
            </div>
          </Container>
        </div>
      )}
      <Footer />
    </>
  );

  function setSlider() {
    // console.log(gallery);
    if (gallery && gallery.data && gallery.data.length > 0) {
      return (
        <div className="product-gallery-img">
          {/* <CCarousel controls indicators touch dark>
            {gallery.data.map((item, index) => (
              <CCarouselItem key={index}>
                <CImage
                  className="d-block w-100"
                  src={item.thumbnail}
                  alt={`slide ${index + 1}`}
                />
              </CCarouselItem>
            ))}
          </CCarousel> */}
          {/* <Splide
            options={{
              rewind: true,
              width: 800,
              gap: "1rem",
              rewind: true,
              pagination: false,
              cover: false,
              // cover: true,
            }}
          >
            {gallery.data.map((item, index) => (
              <SplideSlide key={index}>
                <img src={item.thumbnail} alt={`Image ${index + 1}`} />
              </SplideSlide>
            ))}
           
          </Splide> */}
          {/* <Splide
            options={{
              type: "slide",
              perPage: 1,
              pagination: false,
              rewind: true,
              cover: true,
            }}
            onInit={(splide) => {
              new Splide(".thumbnail-slider", {
                fixedWidth: 100,
                height: 60,
                gap: 10,
                cover: true,
                isNavigation: true,
                focus: "center",
                breakpoints: {
                  600: {
                    fixedWidth: 66,
                  },
                },
              }).mount();
            }}
          >
            {gallery.data.map((item, index) => (
              <SplideSlide key={index}>
                <img src={item.orignal} alt={`Image ${index + 1}`} />
              </SplideSlide>
            ))}
          </Splide> */}

          {/* <div className="thumbnail-slider">
            <Splide>
              {gallery.data.map((item, index) => (
                <SplideSlide key={index}>
                  <img src={item.thumbnail} alt={`Thumbnail ${index + 1}`} />
                </SplideSlide>
              ))}
            </Splide>
          </div> */}

          <Carousel
            dynamicHeight={true}
            showArrows={true}
            showIndicators={true}
          >
            {gallery.data.map((item, index) => (
              <div key={index}>
                <img src={item.thumbnail}></img>
              </div>
            ))}
          </Carousel>

          <ul className="product-share-icons">
            <li>
              <Link to="/">
                <Icon icon="fluent:print-20-filled" />
              </Link>
            </li>
            <li>
              <Link to="/">
                <Icon icon="fluent:mail-16-filled" />
              </Link>
            </li>
            <li>
              <Link to="/">
                <Icon icon="ant-design:twitter-outlined" />
              </Link>
            </li>
            <li>
              <Link to="/">
                <Icon icon="bxl:facebook-circle" />
              </Link>
            </li>
          </ul>
        </div>
      );
    } else {
      return <Image src="./assets/images/not-found.png" />;
    }
  }

  function itemDescription() {
    if (data && data.item) {
      return (
        <Col lg={6}>
          <div className="product-gallery-info">
            <h3>{data.item.title.toUpperCase()}</h3>
            <h4>Basic Information</h4>
            <Row>
              <Col lg={12}>
                <h5 className="">
                  SKU # : <span>{data.item.assetNumber}</span>{" "}
                </h5>
              </Col>
              <Col lg={12}>
                <h5 className="">
                  Location :{" "}
                  <span>
                    <Stack direction="horizontal" gap={2}>
                      {locations.map((location, index) => (
                        <Badge key={index} bg="secondary" text="light">
                          {location}
                        </Badge>
                      ))}
                    </Stack>
                  </span>{" "}
                </h5>
              </Col>
              {/* <Col lg={6}>
                <h5 className="">
                  SKU : <span>{data.item.sku} </span>{" "}
                </h5>
              </Col> */}
            </Row>
            <div className="qty-wrap">
              <h5>
                {" "}
                Available Qty: <span>{totalStock}</span>
              </h5>
            </div>
            <div className="qty-wrap">
              <h5>
                Available to Rent:{" "}
                <span>{data.item.isRental ? "Yes" : "No"}</span>
              </h5>
            </div>
            <h5 className="">
              Condition Inspection - :{" "}
              <span> {data.item.guaranteeOrAsis && data.item.condition} </span>
            </h5>
            <h5>
              Guarantee or ASIS :{" "}
              <span>
                {data.item.guaranteeOrAsis && data.item.guaranteeOrAsis}
              </span>{" "}
            </h5>
            <h4>
              Payment Details{" "}
              <OverlayTrigger placement="top" overlay={tooltip}>
                <span>
                  <Icon icon="material-symbols:info-outline" />
                </span>
              </OverlayTrigger>
            </h4>
            <Row className="mb-3 pricing">
              {data.item.salePrice !== 0 && (
                <Col lg={3}>
                  <h5 className="">
                    Asking Price : <span>${lowestPrice}</span>{" "}
                  </h5>
                </Col>
              )}
              {/* <Col lg={3}>
                {data.item.isRental && (
                  <>
                    <h5 className="">
                      Weekly Rent: <span>${data.item.rentWeekly}</span>
                    </h5>
                  </>
                )}
              </Col> */}
              <Col lg={3}>
                {data.item.isRental && (
                  <>
                    <h5 className="">
                      Monthly Rent:{" "}
                      <span>${data.item.rentMonthly.toLocaleString()}</span>
                    </h5>
                  </>
                )}
              </Col>
              <Col lg={3}>
                {data.item.isRental && (
                  <>
                    <h5 className="">
                      Yearly Rent:{" "}
                      <span>${data.item.rentYearly.toLocaleString()}</span>
                    </h5>
                  </>
                )}
              </Col>
              <Col lg={3}>
                {/* {data.item.isRental && (
                  <>
                    <h5 className="">
                      Yearly Rent:{" "}
                      <span>${data.item.rentYearly.toLocaleString()}</span>
                    </h5>
                  </>
                )} */}
              </Col>
              {/* <Col lg={6}> */}
              {/* <h5 className=""> */}
              {/* Delivery/Shipping Cost :{" "} */}
              {/* {data.item.packagingCostData && (
                    <span>{data.item.packagingCostData}</span> // TODO : add pop us to get shipping cost
                  )} */}
              {/* <Button onClick={handleshow}>Get Shipping Cost</Button> */}
              {/* </h5> */}
              {/* </Col> */}
              {/* <Col lg={6}>
                <h5>
                  New Replacement Cost:{" "}
                  <span> ${data.item.newReplacementCostId}</span>
                </h5>
              </Col> */}
              {/* <Col lg={6}>{acceptPaymentfun()}</Col> */}
            </Row>
            <Row>
              <Col lg={12}>
                <Table striped bordered hover>
                  {/* <thead>
                    <tr>
                      <th>Location</th>
                      <th>Price</th>
                      <th>
                        Truckload{" "}
                        <span>
                          <OverlayTrigger placement="top" overlay={tooltip2}>
                            <span>
                              <Icon icon="material-symbols:info-outline" />
                            </span>
                          </OverlayTrigger>
                        </span>
                      </th>
                    </tr>
                  </thead> */}
                  <tbody>
                    <tr>
                      <td>Comfort</td>
                      <td>${comfortPrice.toLocaleString()}</td>
                      {/* <td>${comfortDropPrice.toLocaleString()}</td> */}
                    </tr>
                    <tr>
                      <td>Del Rio</td>
                      <td>${delRioPrice.toLocaleString()}</td>
                      {/* <td>${delRioDropPrice.toLocaleString()}</td> */}
                    </tr>
                    <tr>
                      <td>Houston</td>
                      <td>${houstonPrice.toLocaleString()}</td>
                      {/* <td>${houstonDropPrice.toLocaleString()}</td> */}
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
            <div className="btns-wrap btns-33Wrap">
              {/* <a
                type="button"
                onClick={() => addCart(data.item)}
                className="btn btn-blue btns-33"
              >
                Add To Cart
              </a> */}
              {/* <a
                type="button"
                onClick={() => buyNow(data.item)}
                className="btn btn-blue btns-33"
              >
                Buy Now
              </a> */}
              <a
                onClick={handleshow}
                type="button"
                className="btn btn-blue btns-33"
              >
                Get a Quote
              </a>
              <div className="max-save btns-33">
                {loading ? (
                  <Button
                    className="btn btn-blue w-100 btn-block  mt-3"
                    disabled
                    style={{ position: "relative" }}
                  >
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      style={{
                        color: "white",
                        top: "calc(50% - 12px)",
                        left: "calc(50% - 12px)",
                      }}
                    />
                  </Button>
                ) : (
                  <Button
                    className="btn btn-blue w-100 btn-block  mt-3"
                    type="button"
                    onClick={() => saveLaterItem(data.item)}
                  >
                    Save for later
                  </Button>
                )}
              </div>
              <a
                href="tel:830-282-0000"
                className="btn btn-blue btn-icon btns-33"
              >
                <Icon icon="fluent:call-12-filled" className="me-2" />
                Call Us
              </a>
              <a
                href={
                  "mailto:sales@procontainers.com?subject=" +
                  data.item.title +
                  " - " +
                  data.item.assetNumber
                }
                className="btn btn-blue btn-icon btns-33"
              >
                <Icon icon="ic:round-email" className="me-2" />
                Email Us
              </a>
            </div>
          </div>
        </Col>
      );
    }

    function acceptPaymentfun() {
      let acc = data.item.paymentAccept.replace("]", "");
      acc = acc.replace("[", "");
      acc = acc.replace("]", "");
      acc = acc.replace(/"/g, " ");

      return (
        <>
          <h5>Payment Types Accepted : {acc && <span> {acc}</span>}</h5>
        </>
      );
    }
  }

  function N_AIfNull(val) {
    if (
      val == null ||
      val.toString() == "" ||
      val.toString() == "0" ||
      val.toString() == "[]" ||
      val == undefined
    ) {
      return <p>N/A</p>;
    } else {
      return <p>{val}</p>;
    }
  }
  function inspectionReport() {
    if (data && data.item) {
      return (
        <div className="inspection-info">
          <Row>
            <Col lg={12}>
              <div className="bg-white">
                <h3>INSPECTION REPORT</h3>
              </div>
            </Col>
            <Col lg={12}>
              <div className="bg-white report-info">
                <h5>Additional Item Detail</h5>
                <Row>
                  <Col lg={6}>
                    <div className="report-detail-item report-detail-Header">
                      <h6>DESCRIPTION</h6>
                      <h6>DETAILS</h6>
                    </div>
                    <div className="report-detail-item">
                      <p>Category : </p>
                      <p>{N_AIfNull(data.item.industryName)}</p>
                    </div>
                    <div className="report-detail-item">
                      <p>Container Door Options : </p>
                      <p>{N_AIfNull(data.item.typeName)}</p>
                    </div>
                    <div className="report-detail-item">
                      <p>Container Condition Inspection : </p>
                      <p>{N_AIfNull(data.item.condition)}</p>
                    </div>
                    <div className="report-detail-item">
                      <p>Container Color : </p>
                      <p>{N_AIfNull(data.item.color)}</p>
                    </div>
                    <div className="report-detail-item">
                      <p>Container Accessorials : </p>
                      <p>{N_AIfNull(data.item.accessorial)}</p>
                    </div>
                    <div className="report-detail-item">
                      <p>Manufacturer : </p>
                      <p>{N_AIfNull(data.item.menufacturer)}</p>
                    </div>
                    <div className="report-detail-item">
                      <p>Serial #: </p>
                      <p>{N_AIfNull(data.item.serialNo)}</p>
                    </div>
                    {/* <div className="report-detail-item">
                      <p>HP / AMP / KW / KVA : </p>
                      <p>{N_AIfNull(data.item.hP_AMP_KW_KVA)}</p>
                    </div> */}
                    {/* <div className="report-detail-item">
                      <p>Voltage : </p>
                      <p>
                        {(() => {
                          let arr = JSON.parse(data.item.voltage);
                          if (arr && arr[0] == "N/A") {
                            return (
                              <>
                                <p>N/A</p>
                              </>
                            );
                          } else {
                            if (data.item.voltage != null) {
                              let v = data.item.voltage.replace("]", "");
                              v = v.replace("[", "");
                              v = v.replace(/"/g, " ");
                              if (v != null) {
                                return <>{v}</>;
                              }
                            }
                          }
                        })()}
                      </p>
                    </div> */}
                    <div className="report-detail-item">
                      <p>Length (Inches): </p>
                      <p>{N_AIfNull(data.item.lengthInches)}</p>
                    </div>
                    {/* <div className="report-detail-item">
                      <p>Estimated Packaging Weight: </p>
                      <p>{N_AIfNull(data.item.estiamtedPackaginWeight)}</p>
                    </div> */}
                    {/* <div className="report-detail-item">
                      {(() => {
                        let url = "";
                        if (
                          data.item.manuFacturers_Specs &&
                          data.item.manuFacturers_Specs != null
                        ) {
                          const jsonObject = JSON.parse(
                            data.item.manuFacturers_Specs
                          );
                          url = jsonObject.url;
                        }
                        if (url && url != "" && url != null) {
                          return (
                            <>
                              <p>Manufacturers Specs: </p>
                              <button
                                className="btn btn-blue"
                                onClick={(e) => {
                                  e.preventDefault();
                                  getPdfAttachementQB(url);
                                }}
                              >
                                Open Manufacturers Specs
                              </button>
                            </>
                          );
                        } else {
                          return (
                            <>
                              <p>Manufacturers Specs: </p>
                              <p>No Manufacturer Specifications Available</p>
                            </>
                          );
                        }
                      })()}
                    </div> */}
                    <div className="report-detail-item">
                      <p>Estimated Total Weight: </p>
                      <p>{N_AIfNull(data.item.estimatedTotalWeight)}</p>
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="report-detail-item report-detail-item2 report-detail-Header">
                      <h6>DESCRIPTION</h6>
                      <h6>DETAILS</h6>
                    </div>
                    <div className="report-detail-item">
                      <p>Container Size : </p>
                      <p>{N_AIfNull(data.item.categoryName)}</p>
                    </div>

                    {/* <div className="report-detail-item">
                      <p>Model : </p>
                      <p>{N_AIfNull(data.item.model)}</p>
                    </div> */}
                    {/* <div className="report-detail-item">
                      <p>Year : </p>
                      <p>{N_AIfNull(data.item.year)}</p>
                    </div> */}
                    {/* <div className="report-detail-item">
                      <p>Amps : </p>
                      <p>{N_AIfNull(data.item.amps)}</p>
                    </div> */}
                    {/* <div className="report-detail-item">
                      <p>Phase : </p>
                      <p>{N_AIfNull(data.item.phase)}</p>
                    </div> */}
                    <div className="report-detail-item">
                      <p>Width (Inches): </p>
                      <p>{N_AIfNull(data.item.widthInches)}</p>
                    </div>
                    <div className="report-detail-item">
                      <p>Height (Inches): </p>
                      <p>{N_AIfNull(data.item.heightInches)}</p>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            {data.item.background != "" && data.item.background != null && (
              <Col lg={12}>
                <div className="bg-white report-info report-info2">
                  <h5>Background</h5>
                  <Row>
                    <Col lg={12}>
                      <div className="report-detail-item">
                        <p
                          dangerouslySetInnerHTML={{
                            __html: data.item.background,
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            )}
            {data.item.factoryInformationOnly != null &&
              data.item.factoryInformationOnly != "" && (
                <Col lg={12}>
                  <div className="bg-white report-info report-info2">
                    <h5>Factory Information</h5>
                    <Row>
                      <Col lg={12}>
                        <div className="report-detail-item">
                          <p
                            dangerouslySetInnerHTML={{
                              __html: data.item.factoryInformationOnly,
                            }}
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              )}
          </Row>
          {show != null && <MakeOffer item={data.item} show={show} />}

          <LoginModal item={data.item} show={showLogin} isBuyNow={isBuyNow} />
          {/* <GetShippingCost /> */}
        </div>
      );
    }
  }

  function getPdfAttachementQB(url) {
    axios
      .get(`https://api.quickbase.com/v1${url}`, appConstants.config)
      .then((response) => {
        const byteCharacters = atob(response.data);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: "application/pdf" });
        const url = URL.createObjectURL(blob);
        window.open(url, "_blank");
      })
      .catch((error) => {
        console.log(error);
      });
  }
}

export default InventoryDetail;

import React, { useState, useEffect } from "react";
import { Image, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import { handleAddCart, getTotals } from "../../Slices/CartSlice";
import { useDispatch } from "react-redux";
import MakeOffer from "../../MakeOffer/MakeOffer";
import LoginModal from "../../LoginPage/LoginModal";
import { toast } from "react-toastify/dist/react-toastify";
import "./InventoryProduct.css";
import { Col, Badge, Stack } from "react-bootstrap";
import AddToCartOrCheckOut from "../../AddtoCartOrCheckout/addtocart";
import { hu } from "date-fns/locale";

function InventoryProduct(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [show, setshow] = useState(null);

  const [showLogin, setShowLogin] = useState(null);
  const [showCheckoutPopUp, setshowCheckoutPopUp] = useState(null);
  const [isBuyNow, setIsBuyNow] = useState(false);
  const [lowestPrice, setLowestPrice] = useState(null);
  const [sumNo, setsumNo] = useState(23333);
  // const [totalStock, setTotalStock] = useState(null);

  console.log(sumNo.toLocaleString("en-US"));

  const handleshow = () => {
    if (show == null) {
      if (show == null) {
        setshow(true);
      }
    } else {
      setshow(!show);
    }
  };

  console.log(props.productDetail);
  const handleShowLogin = () => {
    if (showLogin == null) {
      if (showLogin == null) {
        setShowLogin(true);
      }
    } else {
      setShowLogin(!showLogin);
    }
  };

  const totalStock =
    parseInt(props.productDetail.comfortStock, 10) +
    props.productDetail.delRioStock +
    props.productDetail.houstonStock +
    parseInt(props.productDetail.dropStockMaster, 10);

  const calculateLowestPrice = () => {
    // Convert input values to numbers
    const comfortDropPrice = parseFloat(props.productDetail.comfortDropPrice);
    const comfortPrice = parseFloat(props.productDetail.comfortPrice);
    const delRioDropPrice = parseFloat(props.productDetail.delRioDropPrice);
    const delRioPrice = parseFloat(props.productDetail.delRioPrice);
    const houstonDropPrice = parseFloat(props.productDetail.houstonDropPrice);
    const houstonPrice = parseFloat(props.productDetail.houstonPrice);

    console.log(
      comfortDropPrice,
      comfortPrice,
      delRioDropPrice,
      delRioPrice,
      houstonDropPrice,
      houstonPrice
    );

    // Filter out NaN values
    const prices = [
      comfortDropPrice,
      comfortPrice,
      delRioDropPrice,
      delRioPrice,
      houstonDropPrice,
      houstonPrice,
    ].filter((price) => !isNaN(price) && price !== 0);

    console.log("Prices", prices);

    // Check if there are valid prices
    if (prices.length > 0) {
      // Calculate the lowest price
      const minPrice = Math.min(...prices);

      // Update the state with the lowest price
      setLowestPrice(minPrice.toLocaleString("en-US"));
      console.log("MinPrice", minPrice);
    } else {
      // Handle invalid input
      setLowestPrice(null);
    }
  };

  useEffect(() => {
    calculateLowestPrice();
  }, [props.productDetail]);

  // console.log(totalStock);
  function buyNow(selectedItem) {
    var loginData = JSON.parse(localStorage.getItem("login"));
    if (loginData == null) {
      setIsBuyNow(true);
      handleShowLogin();
    } else {
      dispatch(handleAddCart(selectedItem));
      navigate("/ShoppingCart");
    }
  }

  function CheckoutPopUp(selectedItem) {
    var loginData = JSON.parse(localStorage.getItem("login"));
    if (loginData == null) {
      handleShowLogin();
      setshowCheckoutPopUp(true);
    } else {
      dispatch(handleAddCart(selectedItem));
      navigate("/ShoppingCart");
    }
  }

  function addCart(selectedItem) {
    var loginData = JSON.parse(localStorage.getItem("login"));
    if (loginData == null) {
      setIsBuyNow(false);
      handleShowLogin();
    } else {
      dispatch(handleAddCart(selectedItem));
      dispatch(getTotals());
      setshowCheckoutPopUp(true);
      toast.success("Container Added to Cart");
    }
  }

  function detailPage() {
    const queryParams = itemQuaryParams();
    window.open(
      `/InventoryDetail/${props.productDetail.id}?${queryParams.toString()}`,
      "_blank"
    );
  }

  console.log(props.productDetail);

  function itemQuaryParams() {
    const item = {
      industry: props.productDetail.industryName,
      category: props.productDetail.categoryName,
      type: props.productDetail.typeName,
      description: props.productDetail.description,
      assetNo: props.productDetail.assetNumber,
      location: props.productDetail.location,
      model: props.productDetail.model,
      manufacturer: props.productDetail.menufacturer,
    };
    const queryParams = new URLSearchParams();
    queryParams.set("item", JSON.stringify(item));
    return queryParams;
  }
  function detailPageMobile() {
    sessionStorage.setItem("scrollPosition", window.pageYOffset);
    const queryParams = itemQuaryParams();
    navigate(
      `/InventoryDetail/${props.productDetail.id}?${queryParams.toString()}`,
      { state: { id: props.id } }
    );
  }

  return (
    <>
      <div className="productItem-wrap DesktopProduct">
        {(() => {
          if (props.productDetail.coverImageURL) {
            return (
              <div>
                <div
                  className="productItem-Img"
                  id={`product-${props.productDetail.id}`}
                  onClick={() => {
                    detailPage();
                  }}
                  dangerouslySetInnerHTML={{
                    __html: props.productDetail.coverImageURL,
                  }}
                ></div>
                <div className="RentTag">
                  {props.productDetail.isRental == true && (
                    <a className="rentTa">Available For Rent</a>
                  )}
                </div>
              </div>
            );
          } else {
            return (
              <div className="productItem-Img">
                {" "}
                <Image src="/assets/images/not-found.png" />{" "}
              </div>
            );
          }
        })()}

        <div className="productItem-Info ">
          <h4
            className="title_link"
            onClick={() => {
              detailPage();
            }}
          >
            {props.productDetail.description.toUpperCase()}
          </h4>
          <ul className="advertising-wrap">
            {props.productDetail.industryName != null && (
              <li>
                <a href="/#">{props.productDetail.industryName}</a>
              </li>
            )}
            {props.productDetail.condition != null && (
              <li>
                <a href="/#">{props.productDetail.condition}</a>
              </li>
            )}

            {props.productDetail.categoryName != null && (
              <li>
                <a href="/#">{props.productDetail.categoryName}</a>
              </li>
            )}
            {props.productDetail.typeName != null && (
              <li>
                <a href="/#">{props.productDetail.typeName}</a>
              </li>
            )}

            {/* {props.productDetail.typeName != null && (
              <li>
                <a href="/#">{props.productDetail.typeName}</a>
              </li>
            )} */}
          </ul>

          <div className="rentPrice">
            {/* <h5> ${props.productDetail.salePrice}</h5> */}
            {/* <Row className="mb-3">
              {props.productDetail.salePrice !== 0 && (
                <Col lg={3}>
                  <p className="">
                    Appraised Value: ${props.productDetail.salePrice}
                  </p>
                </Col>
              )}
            </Row> */}
            {/* <ul className="list-wrap">
              <li>
                <p>
                  <strong>Appraised Value:</strong>
                  <span> ${props.productDetail.salePrice}</span>
                </p>
              </li>
            </ul> */}

            {/* {props.productDetail.isRental == true ||
              (props.productDetail.salePrice && (
                <ul className="list-wrap" style={{ marginRight: "-50px" }}>
                  <li>
                    <p>
                      <strong>Appraised Value:</strong>
                      <span> ${props.productDetail.salePrice}</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>Weekly Rent:</strong>
                      <span> ${props.productDetail.rentWeekly}</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>Monthly Rent:</strong>
                      <span> ${props.productDetail.rentMonthly}</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>Yearly Rent:</strong>
                      <span> ${props.productDetail.rentYearly}</span>
                    </p>
                  </li>
                </ul>
              ))} */}
          </div>
          {props.productDetail.salePrice !== 0 && (
            <ul className="list-wrap">
              <li>
                <p>
                  <strong>Asking Price:</strong>
                  <span> ${lowestPrice}</span>
                </p>
              </li>
              <li>
                {props.productDetail.salePrice !== 0 && (
                  <div>
                    <ul className="list-wrap">
                      {props.productDetail.isRental && (
                        <>
                          {/* <li>
                      <p>
                        <strong>Weekly Rent:</strong>
                        <span> ${props.productDetail.rentWeekly}</span>
                      </p>
                    </li> */}
                          <li>
                            <p>
                              <strong>Monthly Rent: </strong>
                              <span>
                                $
                                {props.productDetail.rentMonthly.toLocaleString()}
                              </span>
                            </p>
                          </li>
                          {/* <li>
                      <p>
                        <strong>Yearly Rent:</strong>
                        <span> ${props.productDetail.rentYearly}</span>
                      </p>
                    </li> */}
                        </>
                      )}
                    </ul>
                  </div>
                )}
              </li>
            </ul>
          )}
          <ul className="list-wrap">
            <li>
              <p>
                <strong>Stock Availibility:</strong>
                <span> {totalStock}</span>
              </p>
            </li>
          </ul>
          <ul className="list-wrap">
            <li className="pills">
              <p>
                <strong>Stock Locations:</strong>
              </p>
              <Stack direction="horizontal" gap={2}>
                {/* <Badge bg="secondary" text="light">
                  {props.productDetail.location}
                </Badge> */}
                <Badge bg="secondary" text="light">
                  {props.productDetail.location}
                </Badge>
                {/* <Badge bg="secondary">TX Comfort 78013</Badge>
                <Badge bg="secondary">TX Del Rio 78840</Badge>
                <Badge bg="secondary">TX Houston</Badge> */}
              </Stack>
            </li>
            {/* <li>
              <p>
                <strong>Container Size:</strong>{" "}
                <Link to="/"> {props.productDetail.categoryName}</Link>{" "}
              </p>
            </li> */}
          </ul>

          {/* <ul className="list-wrap"> */}

          {/* </ul> */}
          <div className="btn-wrap">
            {/* <a
              className="btn btn-blue"
              onClick={() => buyNow(props.productDetail)}
            >
              Buy Now
            </a> */}
            <a onClick={handleshow} className="btn btn-blue">
              Get a Quote
            </a>
            {/* <a
              type="button"
              onClick={() => addCart(props.productDetail)}
              className="btn btn-blue"
            >
              Add To Cart
            </a> */}
            <i></i>
            <a href="tel:830-282-0000" className="btn btn-blue btn-arrow">
              <Icon className="me-2" icon="fluent:call-16-filled" /> Call Us
            </a>
            <a
              href={
                "mailto:sales@procontainers.com?subject=" +
                props.productDetail.title +
                " - " +
                props.productDetail.assetNumber
              }
              className="btn btn-blue btn-arrow btnemailmobile"
            >
              <Icon className="me-2" icon="ic:round-email" /> Email Us
            </a>
            <div className="btn-blue sku">
              <p className="sku-p">
                SKU: <span>{props.productDetail.assetNumber}</span>
              </p>
            </div>
          </div>
        </div>
        {show != null && <MakeOffer item={props.productDetail} show={show} />}

        <AddToCartOrCheckOut
          item={props.productDetail}
          show={showCheckoutPopUp}
          isBuyNow={isBuyNow}
        />
        <LoginModal
          show={showLogin}
          item={props.productDetail}
          handleClose={handleShowLogin}
          isBuyNow={isBuyNow}
        />
      </div>
      <div className="productItem-wrap MobileProduct">
        {(() => {
          if (props.productDetail.coverImageURL) {
            return (
              <div>
                <div
                  className="productItem-Img"
                  onClick={() => {
                    detailPageMobile();
                  }}
                  dangerouslySetInnerHTML={{
                    __html: props.productDetail.coverImageURL,
                  }}
                ></div>
                <div className="RentTag">
                  {props.productDetail.isRental == true && (
                    <a className="rentTa">Available For Rent</a>
                  )}
                </div>
              </div>
            );
          } else {
            // return <img src={props.productDetail.coverImageURL}/>
            return (
              <div className="productItem-Img">
                {" "}
                <Image src="/assets/images/not-found.png" />{" "}
              </div>
            );
          }
        })()}

        <div className="productItem-Info">
          {/* <Link to="/" className="heart-Ico heart-Ico2">
          <Icon icon="akar-icons:heart" />
        </Link> */}

          <h4
            className="title_link"
            onClick={() => {
              detailPageMobile();
            }}
          >
            {props.productDetail.description}
          </h4>
          <ul className="advertising-wrap">
            {props.productDetail.industryName != null && (
              <li>
                <a href="/#">{props.productDetail.industryName}</a>
              </li>
            )}
            {props.productDetail.categoryName != null && (
              <li>
                <a href="/#">{props.productDetail.categoryName}</a>
              </li>
            )}
            {props.productDetail.typeName != null && (
              <li>
                <a href="/#">{props.productDetail.typeName}</a>
              </li>
            )}
          </ul>
          {/* <ul className="list-wrap">
            <li>
              <p>
                <strong>Location:</strong> {props.productDetail.location}
              </p>
            </li>
            <li>
              <p>
                <strong>Category:</strong>{" "}
                <Link to="/"> {props.productDetail.categoryName}</Link>{" "}
              </p>
            </li>

            <li><p><strong>New Replacement Cost :</strong> $ {props.productDetail.newReplacementCostId}</p></li>
          </ul> */}

          {/* <h5> ${props.productDetail.salePrice}</h5> */}
          {props.productDetail.salePrice !== 0 && (
            <ul>
              <li>
                <p>
                  {/* <strong>Starting at:</strong>
                  <span> ${lowestPrice}</span> */}
                </p>
              </li>
            </ul>
          )}
          <ul className="list-wrap">
            <li>
              <p>
                <strong>Stock Availibility: </strong>
                <span>{totalStock}</span>
              </p>
            </li>
          </ul>
          <ul className="list-wrap">
            <li className="pills">
              <p>
                <strong>Stock Locations:</strong> {props.productDetail.location}
              </p>
              <Stack direction="horizontal" gap={2}>
                {/* <Badge bg="secondary" text="light">
                  TX Comfort 78013
                </Badge>
                <Badge bg="secondary">TX Del Rio 78840</Badge>
                <Badge bg="secondary">TX Houston</Badge> */}
                <Badge bg="secondary" text="light">
                  {props.productDetail.location}
                </Badge>
              </Stack>
            </li>
            {/* <li>
              <p>
                <strong>Container Size:</strong>{" "}
                <Link to="/"> {props.productDetail.categoryName}</Link>{" "}
              </p>
            </li> */}
          </ul>
          {props.productDetail.salePrice !== 0 && (
            <div>
              <ul className="list-wrap">
                {props.productDetail.isRental && (
                  <>
                    {/* <li>
                      <p>
                        <strong>Weekly Rent:</strong>
                        <span> ${props.productDetail.rentWeekly}</span>
                      </p>
                    </li> */}
                    <li>
                      <p>
                        <strong>Monthly Rent: </strong>
                        <span>
                          {" "}
                          {/* {props.productDetail.rent.Monthly.toLocaleString("en-US)} */}
                          ${props.productDetail.rentMonthly.toLocaleString()}
                        </span>
                      </p>
                    </li>
                    {/* <li>
                      <p>
                        <strong>Yearly Rent:</strong>
                        <span> ${props.productDetail.rentYearly}</span>
                      </p>
                    </li> */}
                  </>
                )}
              </ul>
            </div>
          )}
          <div className="rentPrice">
            {props.productDetail.isRental == true && (
              // <Col lg={12} className="rentPrice2">
              //   <div className="report-detail-item">
              //     <p>${props.productDetail.salePrice}</p>
              //   </div>
              //   <div className="report-detail-item">
              //     <p>
              //       <strong>Weekly Rent:</strong>{" "}
              //       {props.productDetail.rentWeekly}
              //     </p>
              //   </div>
              //   <div className="report-detail-item">
              //     <p>
              //       <strong>Monthly Rent:</strong>{" "}
              //       {props.productDetail.rentMonthly}
              //     </p>
              //   </div>
              //   <div className="report-detail-item">
              //     <p>
              //       <strong>Yearly Rent:</strong>{" "}
              //       {props.productDetail.rentYearly}
              //     </p>
              //   </div>
              // </Col>
              // <Row className="mb-3">
              //   {/* {props.productDetail.salePrice !== 0 && (
              //     <Col lg={3}>
              //       <p className="">
              //         Appraised Value:{" "}
              //         <span>${props.productDetail.salePrice}</span>{" "}
              //       </p>
              //     </Col>
              //   )} */}
              //   <Col lg={3}>
              //     {props.productDetail.isRental && (
              //       <>
              //         <p className="">
              //           Weekly Rent: ${props.productDetail.rentWeekly}
              //         </p>
              //       </>
              //     )}
              //   </Col>
              //   <Col lg={3}>
              //     {props.productDetail.isRental && (
              //       <>
              //         <p className="">
              //           Monthly Rent:{" "}
              //           <span>${props.productDetail.rentMonthly}</span>
              //         </p>
              //       </>
              //     )}
              //   </Col>
              //   <Col lg={3}>
              //     {props.productDetail.isRental && (
              //       <>
              //         <p className="">
              //           Yearly Rent:{" "}
              //           <span>${props.productDetail.rentYearly}</span>
              //         </p>
              //       </>
              //     )}
              //   </Col>
              //   {/* <Col lg={6}> */}
              //   {/* <h5 className=""> */}
              //   {/* Delivery/Shipping Cost :{" "} */}
              //   {/* {data.item.packagingCostData && (
              //       <span>{data.item.packagingCostData}</span> // TODO : add pop us to get shipping cost
              //     )} */}
              //   {/* <Button onClick={handleshow}>Get Shipping Cost</Button> */}
              //   {/* </h5> */}
              //   {/* </Col> */}
              //   {/* <Col lg={6}>
              //   <h5>
              //     New Replacement Cost:{" "}
              //     <span> ${data.item.newReplacementCostId}</span>
              //   </h5>
              // </Col> */}
              //   {/* <Col lg={6}>{acceptPaymentfun()}</Col> */}
              // </Row>
              <ul className="list-wrap">
                {/* <li>
                  <p>
                    <strong>Weekly Rent:</strong>
                    <span> ${props.productDetail.rentWeekly}</span>
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Monthly Rent:</strong>
                    <span> ${props.productDetail.rentMonthly}</span>
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Yearly Rent:</strong>
                    <span> ${props.productDetail.rentYearly}</span>
                  </p>
                </li> */}
              </ul>
            )}
          </div>
          <div className="btn-wrap">
            {/* <a
              className="btn btn-blue"
              onClick={() => buyNow(props.productDetail)}
            >
              Buy Now
            </a> */}
            {/* <a
              type="button"
              onClick={() => addCart(props.productDetail)}
              className="btn btn-blue btnFullCart"
            >
              Add To Cart
            </a>{" "} */}

            <a onClick={handleshow} className="btn btn-blue btnFullCart">
              Get a Quote
            </a>
            <i></i>
            <a href="tel:830-282-0000" className="btn btn-blue btn-arrow">
              <Icon className="me-2" icon="fluent:call-16-filled" /> Call Us
            </a>
            <a
              href={
                "mailto:sales@procontainers.com?subject=" +
                props.productDetail.title +
                " - " +
                props.productDetail.assetNumber
              }
              className="btn btn-blue btn-arrow"
            >
              <Icon className="me-2" icon="ic:round-email" /> Email Us
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default InventoryProduct;
